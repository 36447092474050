.back-to-games {
  margin-top: 10px;
}
.back-to-games a {
  color: white;
}

.main-input {
  background-color: darkslategrey;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.main-input table { width: 100%; }
.main-input .prompt { width: 24px; }
.main-input .command-entry {
  color: #33ff33;
  width: 100%;
  background-color: darkslategrey;
  border: none;
  outline: none !important;
}

.message {
  padding: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
  clear: both;
  border-radius: 10px;
  background-color: #2b2b2b;
  border: 1px solid transparent;
  max-width: 80%;
}

.messages-container {
  max-width: 800px;
}

.persona-user {
  float: right;
  background-color: darkslategrey;
}
